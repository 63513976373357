import React, { useState } from "react";
import { DarkSideSwitch } from "./components/DarkSideSwitch";

import { JobLayout } from "./layouts/JobLayout";
import data from "./data/experiences.json";
import ReactLenis from "@studio-freight/react-lenis";
function App() {
  const [darkMode, setDarkMode] = useState<boolean>(
    localStorage.theme === "dark",
  );
  const actualJob = data.filter((item) => item.status === "IN_PROGRESS")[0];
  const passedJobs = data.filter((item) => item.status !== "IN_PROGRESS");
  const amountOfExp = data
    .filter((item) => item.dates !== undefined)
    .reduce((acc: any, item) => {
      const type = item.typeOfJob.toLowerCase();
      const amount = Number(item.dates!.amount);
      if (!acc[type]) {
        acc[type] = 0;
      }
      acc[type] += amount;
      return acc;
    }, {});
  return (
    <ReactLenis root>
      <div
        className={
          "w-full h-full overflow-y-auto gb-white dark:bg-[#1D183E] font-base lg:px-52 md:px-20 px-5 pt-16"
        }
      >
        <div className={"flex justify-between mb-10"}>
          <div className={"text-black dark:text-white"}>
            <h1 className={"text-4xl font-normal pb-5"}>Lotfi Aanikid</h1>
            <div>
              {["📱0669030718", "📧lotfi@aanikid.fr", "🌐aanikid.fr"].map(
                (item, index) => (
                  <h3 key={index} className={"mb-2"}>
                    {item}
                  </h3>
                ),
              )}
            </div>
          </div>
          <DarkSideSwitch
            onDarkSide={(mode) => {
              setDarkMode(mode);
            }}
          />
        </div>
        <JobLayout isDarkMode={darkMode} data={actualJob} />
        <div className={"flex flex-col lg:flex-row items-center"}>
          <h3
            className={
              "text-2xl dark:text-white lg:text-left text-center lg:ml-10 mb-3 mt-10 lg:mb-10 lg:mt-10"
            }
          >
            Mes Expériences
          </h3>
          <div className="flex text-1xl ml-0 lg:ml-5 text-center mt-0 lg:mt-1 mb-10 lg:mb-0 dark:text-white">
            <p>(</p>
            {Object.keys(amountOfExp).map((type, index, array) => (
              <React.Fragment key={type}>
                <p className={"capitalize"}>
                  {type}: {`${amountOfExp[type]} mois`}
                </p>
                {index !== array.length - 1 && <p>,</p>}
              </React.Fragment>
            ))}
            <p>)</p>
          </div>
        </div>
        <div className={"lg:border-l-2"}>
          {passedJobs.map((item, index) => (
            <div key={index} className={"mb-14"}>
              <JobLayout isDarkMode={darkMode} data={item} />
            </div>
          ))}
        </div>
      </div>
    </ReactLenis>
  );
}

export default App;
