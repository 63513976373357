import React from "react";

type IBadge = {
  title: string;
  textColor: string;
  backgroundColor: string;
};
export const Badge: React.FC<IBadge> = ({
  title,
  textColor,
  backgroundColor,
}) => {
  return (
    <div
      className={`rounded-[25px] ${backgroundColor} w-fit px-4 py-0.5  flex items-center drop-shadow-sm`}
    >
      <p className={`${textColor} text-sm font-bold`}>{title}</p>
    </div>
  );
};
