import React, { useState } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import useDarkSide from "../hooks/useDarkSide";
type TDarkSideSwitch = {
  onDarkSide: (darkMode: boolean) => void;
};
export const DarkSideSwitch: React.FC<TDarkSideSwitch> = ({ onDarkSide }) => {
  const { colorTheme, setTheme } = useDarkSide();
  const [darkSide, setDarkSide] = useState(colorTheme === "light");

  const toggleDarkMode = (checked: boolean) => {
    setTheme(colorTheme);
    setDarkSide(checked);
    onDarkSide(colorTheme === "dark");
  };
  return (
    <div
      className={`rounded-[25px] bg-slate-100 h-10 w-24 p-1 flex items-center drop-shadow-sm ${darkSide ? "justify-end" : "justify-start"}`}
    >
      <div
        className={
          "rounded-full w-10 h-9 bg-white flex items-center justify-center drop-shadow-sm"
        }
      >
        <DarkModeSwitch
          sunColor={"#F59E0B"}
          moonColor={"#4A4374"}
          checked={darkSide}
          onChange={toggleDarkMode}
          size={25}
        />
      </div>
    </div>
  );
};
