import React from "react";
import { Badge } from "../components/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
type IJobData = {
  title: string;
  typeOfJob: string;
  description: Array<string>;
  skills: Array<string>;
  status: string;
  since?: string;
  dates?: {
    start: string;
    end: string;
    amount: string;
  };
};
export type IJobLayout = {
  data: IJobData;
  isDarkMode: boolean;
};
export const JobLayout: React.FC<IJobLayout> = ({ data, isDarkMode }) => {
  return (
    <div
      className={
        data.status == "IN_PROGRESS"
          ? "shadow bg-[#FBFBFB] dark:bg-[#4A4374] px-5 lg:px-10 py-9 rounded-[20px] relative"
          : ""
      }
    >
      {data.status === "IN_PROGRESS" && (
        <div
          className={
            "absolute -top-5 bg-[#4A4374] dark:bg-white px-4 py-2 rounded shadow-md"
          }
        >
          <h3 className={"text-xl text-white dark:text-black"}>Actuellement</h3>
        </div>
      )}
      <div
        className={`flex ${data.status !== "IN_PROGRESS" ? "mb-3 flex-col-reverse justify-center lg:flex-row lg:justify-start" : "items-center"} `}
      >
        {data.status !== "IN_PROGRESS" && (
          <div className={"flex lg:-ml-20"}>
            <div
              className={
                "text-xs mr-[1.15rem] mt-3 lg:mt-0 font-bold dark:text-white flex items-center lg:flex-col"
              }
            >
              <FontAwesomeIcon
                icon={faCalendarDays}
                size={"1x"}
                className={"dark:text-white mr-3 block lg:hidden"}
              />
              <p>{data.dates?.start}</p>
              <p className={"block lg:hidden mx-2"}>-</p>
              <p>{data.dates?.end}</p>
            </div>
            <div
              className={
                "rounded-full w-5 h-5 bg-[#4A4374] mt-2 dark:bg-white lg:block hidden lg:mr-20"
              }
            ></div>
          </div>
        )}
        <div className={"flex"}>
          <h4 className={`font-bold mr-5 text-xl dark:text-white `}>
            {data.title}
          </h4>
          <Badge
            title={data.typeOfJob}
            backgroundColor={"bg-[#373E40]"}
            textColor={"text-white"}
          />
        </div>
      </div>
      {data.status == "IN_PROGRESS" && (
        <div className={"mb-3 flex items-center"}>
          <FontAwesomeIcon
            icon={faCalendarDays}
            size={"1x"}
            className={"dark:text-white mr-3"}
          />
          <p className={"dark:text-white text-sm"}>Depuis {data.since}</p>
        </div>
      )}
      <ul
        className={`list-disc list-inside mb-5 ${data.status !== "IN_PROGRESS" ? "lg:ml-24" : ""}`}
      >
        {data.description.map((item, index) => (
          <li key={index} className={"text-base dark:text-white mb-2"}>
            {item}
          </li>
        ))}
      </ul>
      <div className={data.status !== "IN_PROGRESS" ? "lg:ml-20" : ""}>
        <h4 className={`mb-3 dark:text-white`}>Compétences :</h4>
        <div className={"flex flex-wrap"}>
          {data.skills.map((item, index) => (
            <div className={"mr-5 mt-3"} key={index}>
              <Badge
                title={item}
                backgroundColor={"bg-[#D2EDF5]"}
                textColor={"text-black"}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
